import React, { useCallback, useEffect } from 'react';
import { useAtom, useSetAtom } from 'jotai';
import sendRequest from '../helpers/sendRequest';
import Api from '../api';
import {
  deletingHallLoadingAtom,
  gettingHallsLoadingAtom,
  hallsAtom,
  popUpedHallAtom,
  savingHallLoadingAtom,
  selectedHallAtom,
  updateSelectedHallAtom,
} from '../atoms/hallsAtoms';
import PlanServices from '../services/PlanServices';
import {
  gridDraggedPositionAtom,
  gridZoomScaleAtom,
  objectsListAtom,
  objectsListInitAtom,
  schemeDotsAtom,
  schemeDotsInitAtom,
  selectedObjectIndexAtom,
} from '../atoms/schemeAtoms';
import { closeModalAtom } from '../atoms/globalAtoms';
import deepEqual from '../helpers/deepEqual';
import deepClone from '../helpers/deepClone';
import { selectedAddressAtom } from '../atoms/accountAtoms';

const UseHalls = () => {
  const setGettingHallsLoading = useSetAtom(gettingHallsLoadingAtom);
  const setSavingHallLoading = useSetAtom(savingHallLoadingAtom);
  const setDeletingHallLoading = useSetAtom(deletingHallLoadingAtom);

  const [schemeDots, setSchemeDots] = useAtom(schemeDotsAtom);
  const [schemeDotsInit, setSchemeDotsInit] = useAtom(schemeDotsInitAtom);
  const [objectsList, setObjectsList] = useAtom(objectsListAtom);
  const [objectsListInit, setObjectsListInit] = useAtom(objectsListInitAtom);
  const setGridDraggedPosition = useSetAtom(gridDraggedPositionAtom);
  const setSelectedObjectIndex = useSetAtom(selectedObjectIndexAtom);
  const setGridZoomScale = useSetAtom(gridZoomScaleAtom);
  const closeModal = useSetAtom(closeModalAtom);
  const [selectedAddress] = useAtom(selectedAddressAtom);

  const [halls, setHalls] = useAtom(hallsAtom);
  const [selectedHall, setSelectedHall] = useAtom(selectedHallAtom);
  const [popUpedHall, setPopUpedHall] = useAtom(popUpedHallAtom);
  const updateSelectedHall = useSetAtom(updateSelectedHallAtom);

  const changeSelectedHall = useCallback((val) => {
    const { dots, objects } = PlanServices.parseHallFromServer(val);
    closeModal();
    setSelectedHall(val);
    setSchemeDots(deepClone(dots));
    setSchemeDotsInit(deepClone(dots));
    setObjectsList(deepClone(objects));
    setObjectsListInit(deepClone(objects));
    setGridDraggedPosition([0, 0]);
    setGridZoomScale(0);
    setSelectedObjectIndex(null);
  }, [setSelectedHall]);

  const getHalls = useCallback(async () => {
    if (!selectedAddress) return;
    const data = await sendRequest({
      request: Api.getHalls,
      payload: { address_id: selectedAddress.id },
      warnErrorText: 'while getting halls',
      setLoading: setGettingHallsLoading,
    });

    if (data?.status !== 'ok') return;

    setHalls(data.data.zones);
    changeSelectedHall(data.data.zones[0]);
  }, [setHalls, changeSelectedHall, selectedAddress]);

  const saveHall = useCallback(async (payload) => {
    const data = await sendRequest({
      request: Api.saveHall,
      payload,
      warnErrorText: 'while saving hall',
      setLoading: setSavingHallLoading,
    });

    if (data?.status !== 'ok') return;

    setHalls((prev) => prev.map((i) => (i.id === data.data.zones[0].id ? data.data.zones[0] : i)));
    changeSelectedHall(data.data.zones[0]);
  }, [setHalls, setSelectedHall]);

  const addHall = useCallback(async (payload) => {
    const data = await sendRequest({
      request: Api.addHall,
      payload,
      warnErrorText: 'while adding hall',
      setLoading: setSavingHallLoading,
    });

    if (data?.status !== 'ok') return;

    setHalls((prev) => [...prev, data.data.zone]);
    changeSelectedHall(data.data.zone);
  }, [setHalls, setSelectedHall]);

  const deleteHall = useCallback(async (payload) => {
    const data = await sendRequest({
      request: Api.deleteHall,
      payload,
      warnErrorText: 'while deleting hall',
      setLoading: setDeletingHallLoading,
    });

    if (data?.status !== 'ok') return;

    if (selectedHall?.id === payload.id) {
      if (halls[0].id === selectedHall?.id) changeSelectedHall(halls[1]);
      else changeSelectedHall(halls[0]);
    }
    setHalls((prev) => prev.filter((i) => i.id !== payload.id));
    setPopUpedHall(null);
  }, [selectedHall, setHalls, changeSelectedHall, halls, popUpedHall]);

  const renameHall = useCallback(async (payload) => {
    const data = await sendRequest({
      request: Api.renameHall,
      payload,
      warnErrorText: 'while renaming hall',
      setLoading: setSavingHallLoading,
    });

    if (data?.status !== 'ok') return;

    if (selectedHall?.id === popUpedHall.id) {
      setSelectedHall(data.data.zone);
    }
    setHalls((prev) => prev.map((i) => (i.id === data.data.zone.id ? data.data.zone : i)));
    setPopUpedHall(null);
  }, [popUpedHall, selectedHall, setSelectedHall, setPopUpedHall, setHalls]);

  useEffect(() => {
    if (!selectedHall) return;

    // checking if scheme or objects were updated
    if (selectedHall.isUpdated) return; // already updated

    if (!deepEqual(schemeDots, schemeDotsInit) || !deepEqual(objectsList, objectsListInit)) {
      updateSelectedHall({});
    }
  }, [schemeDots, schemeDotsInit, objectsList, objectsListInit, selectedHall]);

  return {
    getHalls, saveHall, addHall, deleteHall, renameHall, changeSelectedHall,
  };
};

export default UseHalls;
