import React, { useRef, useState } from 'react';
import './css/index.scss';
import SearchIcon from '../../assets/formIcons/search.svg';
import SearchBlueIcon from '../../assets/formIcons/searchBlue.svg';
import CloseIcon from '../../assets/formIcons/close.svg';

function MyInput({
  value,
  onChange,
  className = '',
  wrapperClassName = '',
  error,
  withLeftIcon = true,
  withCloseIcon = true,
  shouldValueConsiderAsFocus = false,
  labelTextTop,
  labelTextBottom,
  disabled,
  isLarge,
  isTimeInput = false,
  functionAtCleaning,
  refToPass,
  ...p
}) {
  const inputRef = refToPass || useRef();
  const [isFocused, setIsFocused] = useState(false);

  const onWrapperClick = () => {
    if (inputRef.current) {
      inputRef.current.focus();
      setIsFocused(true);
    }
  };

  const onClearClick = (ev) => {
    ev.stopPropagation();
    if (functionAtCleaning) {
      functionAtCleaning(ev);
    } else {
      onChange({ target: { value: '' } });
    }
    if (inputRef.current) {
      inputRef.current.blur();
    }
  };
  const handleTimeInput = (e) => {
    const input = e.target.value.replace(/[^\d:]/g, '');
    let formattedInput = input;

    if (input.length > 2 && !input.includes(':')) {
      formattedInput = `${input.slice(0, 2)}:${input.slice(2)}`;
    }

    if (formattedInput.length > 5) {
      formattedInput = formattedInput.slice(0, 5);
    }

    onChange(formattedInput);
  };

  const handleInputChange = (e) => {
    if (isTimeInput) {
      handleTimeInput(e);
    } else {
      onChange(e);
    }
  };

  return (
    <div
      className={`search_input_wrapper ${(isFocused || (value && shouldValueConsiderAsFocus)) ? 'search_input_wrapper_focused' : ''} ${disabled && 'disabled'} ${error && 'error'} ${wrapperClassName}`}
      onClick={onWrapperClick}
    >
      {labelTextTop && <small className="input_small">{labelTextTop}</small>}

      <div className="search_input_left">
        {withLeftIcon && (
        <div className="search_input_search">
          <img src={isFocused ? SearchBlueIcon : SearchIcon} alt="" />
        </div>
        )}
        {isLarge ? (
          <textarea
            ref={inputRef}
            className={`search_input is_textarea ${!withLeftIcon && 'with_padding'} ${className}`}
            disabled={disabled}
            value={value}
            onChange={handleInputChange}
            onBlur={() => setIsFocused(false)}
            {...p}
          />
        ) : (
          <input
            ref={inputRef}
            className={`search_input ${!withLeftIcon && 'with_padding'} ${className}`}
            disabled={disabled}
            value={value}
            onChange={handleInputChange}
            onBlur={() => setIsFocused(false)}
            maxLength={isTimeInput ? 5 : undefined}
            placeholder={isTimeInput ? 'HH:MM' : p.placeholder}
            {...p}
          />
        )}
      </div>

      {withCloseIcon && (
        <div
          onClick={onClearClick}
          className={`search_input_close ${(isFocused || value) ? 'search_input_close_visible' : ''}`}
        >
          <img src={CloseIcon} alt="" />
        </div>
      )}
      <p className="error_text">{error}</p>
      {labelTextBottom && <small className="input_small">{labelTextBottom}</small>}
    </div>
  );
}

export default MyInput;
