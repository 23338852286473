import React from 'react';
import PageTitle from "../../components/PageTitle";

function Help(props) {
    return (
        <div className="help">
            <PageTitle text="Справка" />
        </div>
    );
}

export default Help;
