import React from 'react';
import PageTitle from "../../components/PageTitle";
import MyButton from "../../components/MyButton";
import './css/index.scss';

function Reserves(props) {
    return (
        <div className="reserves">
            <PageTitle text="Резервы"/>
            <div className="reserves_info">
                <div className="reserves_counts">
                    <div>
                        <span className="reserves_counts_grey">Резервов: </span>
                        <span>128</span>
                    </div>
                    <div>
                        <span className="reserves_counts_grey">Гостей: </span>
                        <span>154</span>
                    </div>
                </div>
                <MyButton
                    text="Экспорт"
                    className="reserves_button"
                />
            </div>
        </div>
    );
}

export default Reserves;
