import { api } from './configs';
import { SERVER_URL } from '../constants';

class Api {
  static url = SERVER_URL;

  static auth(body) {
    return api.post('/account/auth', body);
  }

  static init() {
    return api.get('/account/init');
  }

  static logout() {
    return api.get('/account/logout');
  }

  static getHalls(params) {
    return api.get('/account/zones/list', { params });
  }

  static saveHall(body) {
    return api.post('/account/zones/save', body);
  }

  static addHall(body) {
    return api.post('/account/zones/create', body);
  }

  static renameHall(body) {
    return api.post('/account/zones/rename', body);
  }

  static deleteHall(body) {
    return api.post('/account/zones/delete', body);
  }
}

export default Api;
