import { atom } from 'jotai';

const tokenAtom = atom('');

const containerRefCurrentAtom = atom(null);

const modalStateAtom = atom({
  isOpen: false,
  closeFunction: undefined,
  children: null,
  style: undefined,
  closeOnPressOutside: true,
});

const closeModalAtom = atom(null, (get, set) => {
  const newObj = { ...get(modalStateAtom), isOpen: false };
  set(modalStateAtom, newObj);
});

// smaller than 1440px
const isSmallWindowAtom = atom(false);
const isNavBarExpandedAtSmallWindowAtom = atom(false);

export {
  tokenAtom,
  containerRefCurrentAtom,
  modalStateAtom,
  closeModalAtom,
  isSmallWindowAtom,
  isNavBarExpandedAtSmallWindowAtom,
};
