import Reserves from '../pages/Reserves';
import Contacts from '../pages/Contacts';
import Plan from '../pages/Plan';
import Integration from '../pages/Integration';
import Help from '../pages/Help';
import ReservesIcon from '../assets/sidebarIcons/reserve.svg';
import ContactsIcon from '../assets/sidebarIcons/contact.svg';
import PlanIcon from '../assets/sidebarIcons/plan.svg';
import IntegrationIcon from '../assets/sidebarIcons/integration.svg';
import HelpIcon from '../assets/sidebarIcons/help.svg';
import PartnerIcon from '../assets/sidebarIcons/partners.svg';
import Partners from '../pages/Partners';
import PartnersForm from '../pages/Partners/components/PartnersForm';

export const SidebarData = {
  reservations: { // module_name
    title: 'Резервы',
    path: '/reservations',
    icon: ReservesIcon,
    component: <Reserves />,
  },
  contacts: {
    title: 'Контакты',
    path: '/contacts',
    icon: ContactsIcon,
    component: <Contacts />,
  },
  plan: {
    title: 'План помещений',
    path: '/plan',
    icon: PlanIcon,
    component: <Plan />,
  },
  integration: {
    title: 'Интеграция',
    path: '/integration',
    icon: IntegrationIcon,
    component: <Integration />,
  },
  help: {
    title: 'Справка',
    path: '/help',
    icon: HelpIcon,
    component: <Help />,
  },
  partners: {
    title: 'Партнеры',
    path: '/partners',
    icon: PartnerIcon,
    component: <PartnersForm />,
  },
};
