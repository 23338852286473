import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Navigate } from 'react-router';
import { useAtom } from 'jotai/index';
import ProtectedRoutes from './navigation/ProtectedRoutes';
import UnprotectedRoutes from './navigation/UnprotectedRoutes';
import LoginPage from './pages/Login';
import { tokenAtom } from './atoms/globalAtoms';
import LocalStorageServices from './services/LocalStorageServices';
import sendRequest from './helpers/sendRequest';
import Api from './api';
import { addressesAtom, modulesAtom, selectedAddressAtom } from './atoms/accountAtoms';

function App() {
  const [token, setToken] = useAtom(tokenAtom);
  const [, setAddresses] = useAtom(addressesAtom);
  const [, setSelectedAddress] = useAtom(selectedAddressAtom);
  const [modules, setModules] = useAtom(modulesAtom);

  const isAuthorized = Boolean(token);
  const authorizedFirstScreen = modules[0]?.path;
  const unAuthorizedFirstScreen = '/login';

  useEffect(() => {
    const tokenFromStorage = LocalStorageServices.getToken();
    if (tokenFromStorage) {
      setToken(tokenFromStorage);
    }
  }, []);

  useEffect(() => {
    if (token) {
      (async () => {
        const data = await sendRequest({
          request: Api.init,
          warnErrorText: 'while init',
        });

        if (data?.status !== 'ok') return;

        setAddresses(data.data.addresses);
        setSelectedAddress(data.data.addresses[0] || null);
        setModules(data.data.modules);
      })();
    }
  }, [token]);

  return (
    <Router>
      <Routes>
        <Route
          path="/login"
          element={<UnprotectedRoutes authorizedFirstScreen={authorizedFirstScreen} hasAccess={isAuthorized} />}
        >
          <Route index element={<LoginPage />} />
        </Route>

        <Route path="/" element={<ProtectedRoutes hasAccess={isAuthorized} unAuthorizedFirstScreen={unAuthorizedFirstScreen} />}>
          {Object.values(modules).map((item) => (
            <Route key={item.path} path={item.path} element={item.component} />
          ))}
        </Route>

        <Route path="/" element={<Navigate to={isAuthorized ? authorizedFirstScreen : unAuthorizedFirstScreen} replace />} />
        <Route path="*" element={<Navigate to={isAuthorized ? authorizedFirstScreen : unAuthorizedFirstScreen} replace />} />
      </Routes>
    </Router>
  );
}

export default App;
