import { v4 } from 'uuid';
import { OBJECT_TYPES } from '../pages/Plan/constants';
import { angleOfBottomLine, getPoints } from '../helpers/anythingSvgFunctions';

class PlanServices {
  static gridSize = 10000;

  static gridSizeHalf = this.gridSize / 2;

  static initSchemeWidth = 500;

  static initSchemeHeight = 200;

  static dotSize = 16;

  static dotSizeHalf = this.dotSize / 2;

  static lineDotSize = 16;

  static lineDotSizeHalf = this.lineDotSize / 2;

  static lineWidth = 6;

  static lineWidthHalf = this.lineWidth / 2;

  static gridZoomStep = 0.2;

  static gridZoomMax = 2;

  static gridZoomMin = 0.4;

  static objectSizes = {
    main: 100,
    long: 250,
    middle: 60,
    short: 15,
    borderRadiusSquare: '10px',
    borderRadiusRound: '50%',
  };

  static objectSteps = {
    width: 15,
    height: 15,
    rotation: 15,
    x: 30,
    y: 30,
  };

  static getCoordinates = ([width, height, ...args]) => [(this.gridSize - width) / 2, (this.gridSize - height) / 2, ...args];

  static getDotQuarter = (dot, middleDot) => {
    if (dot[0] >= middleDot[0] && dot[1] >= middleDot[1]) return 1;
    if (dot[0] < middleDot[0] && dot[1] >= middleDot[1]) return 2;
    if (dot[0] < middleDot[0] && dot[1] < middleDot[1]) return 3;
    if (dot[0] >= middleDot[0] && dot[1] < middleDot[1]) return 4;
  };

  static getDotNextQuarter = (quarter, sideToRotate) => {
    // this is for rotating 90 degrees
    // const neeQuarter = sideToRotate === 'right' ? quarter + 1 : quarter - 1;
    // return neeQuarter === 5 ? 1 : neeQuarter === 0 ? 4 : neeQuarter;

    const nextQuarter = quarter + 2;
    return nextQuarter > 4 ? nextQuarter - 4 : nextQuarter < 1 ? nextQuarter + 4 : nextQuarter;
  };

  static rotateDotToQuarter = (toQuarter, middleDot, xDiff, yDiff) => {
    // this is for rotating 90 degrees
    // if (toQuarter === 1) return [middleDot[0] + yDiff, middleDot[1] + xDiff];
    // if (toQuarter === 2) return [middleDot[0] - yDiff, middleDot[1] + xDiff];
    // if (toQuarter === 3) return [middleDot[0] - yDiff, middleDot[1] - xDiff];
    // if (toQuarter === 4) return [middleDot[0] + yDiff, middleDot[1] - xDiff];

    if (toQuarter === 1) return [middleDot[0] + xDiff, middleDot[1] + yDiff];
    if (toQuarter === 2) return [middleDot[0] - xDiff, middleDot[1] + yDiff];
    if (toQuarter === 3) return [middleDot[0] - xDiff, middleDot[1] - yDiff];
    if (toQuarter === 4) return [middleDot[0] + xDiff, middleDot[1] - yDiff];
  };

  static rotateDot = (dot, middleDot, sideToRotate) => {
    const quarter = this.getDotQuarter(dot, middleDot);
    const nextQuarter = this.getDotNextQuarter(quarter, sideToRotate);
    const xDiff = Math.abs(middleDot[0] - dot[0]);
    const yDiff = Math.abs(middleDot[1] - dot[1]);

    const newCoords = this.rotateDotToQuarter(nextQuarter, middleDot, xDiff, yDiff);

    return [...newCoords, ...dot.slice(2)];
  };

  static generateNewObject = (object, x, y, objectsList) => {
    let title = '';

    if (['table', 'chair'].includes(object.type)) {
      const tablesCount = objectsList.filter((item) => ['table', 'chair'].includes(item.type)).length;
      title = `№${tablesCount + 1}`;
    }
    const newObject = {
      ...object,
      id: v4(),
      fields: {
        ...object.defaultFields, ...object.fields, x, y, title,
      },
    };
    delete newObject.defaultFields;

    return newObject;
  };

  static getObjectSvgForAndroid = (object) => {
    let svg;
    const strokeWidth = 4;
    const {
      borderRadius, width, height, rotation, shapeCount,
    } = object.fields;

    if (['table', 'chair'].includes(object.type)) {
      svg = `<svg width="${width}" height="${height}" style="transform: rotate(${rotation}deg);">
          <rect
            x="${strokeWidth / 2}"
            y="${strokeWidth / 2}"
            width="${width - strokeWidth}"
            height="${height - strokeWidth}"
            rx="${borderRadius}"
            ry="${borderRadius}"
            fill="#FFFFFF"
            stroke="#DAC1EF"
            stroke-width="4"
          />
        </svg>`;
    } else if (object.type === 'wall') {
      svg = `<svg width="${width}" height="${height}" style="transform: rotate(${rotation}deg);">
          <rect
            x="${0}"
            y="${0}"
            width="${width}"
            height="${height}"
            rx="${borderRadius}"
            ry="${borderRadius}"
            fill="#B5BDC5"
          />
        </svg>`;
    } else if (object.type === 'anything') {
      svg = `<svg
        width="${width}"
        height="${height}"
        viewBox="0 0 ${width} ${width}"
        overflow="visible"
      >
        <polygon
          points="${getPoints({ sides: shapeCount, width })}"
          fill="#AE8CD1"
          transform="rotate(${angleOfBottomLine({ sides: shapeCount, width })} ${width / 2} ${width / 2})"
        />
      </svg>`;
    }

    return svg;
  };

  static convertHallForServer = (schemeDots, objectsList, schemeMiddleDot) => {
    schemeMiddleDot[0] = schemeMiddleDot[0] || schemeMiddleDot[0] === 0 ? schemeMiddleDot[0] : PlanServices.gridSizeHalf;
    schemeMiddleDot[1] = schemeMiddleDot[1] || schemeMiddleDot[1] === 0 ? schemeMiddleDot[1] : PlanServices.gridSizeHalf;
    const dots = schemeDots.map((item) => ({ x: item[0] - schemeMiddleDot[0], y: item[1] - schemeMiddleDot[1] }));

    const xS = dots.map((item) => item.x);
    const yS = dots.map((item) => item.y);
    const [minX, maxX] = [Math.min(...xS), Math.max(...xS)];
    const [minY, maxY] = [Math.min(...yS), Math.max(...yS)];

    const points = dots.map((item) => `${item.x - minX},${item.y - minY}`).join(' ');

    const background_svg = dots.length ? `<svg width="${maxX - minX}" height="${maxY - minY}">
      <polygon points="${points}" fill="#F5F2F7" stroke="#DAC1EF" stroke-width="1"/>
    </svg>;` : '';

    const objects = objectsList.map((item) => {
      const svg = this.getObjectSvgForAndroid(item);
      return {
        type: item.type,
        x: item.fields.x - schemeMiddleDot[0],
        y: item.fields.y - schemeMiddleDot[1],
        name: item.fields.title,
        width: item.fields.width,
        height: item.fields.height,
        rotation: item.fields.rotation,
        border_radius: item.fields.borderRadius,
        shape_count: item.fields.shapeCount,
        background_svg: svg,
        params: ['table', 'chair'].includes(item.type) ? {
          id: item.serverId,
          capacity_min: item.fields.minCap || '1',
          capacity_max: item.fields.maxCap || '4',
        } : undefined,
      };
    });

    return {
      floor: { dots, background_svg },
      objects,
    };
  };

  static parseHallFromServer = (hall) => {
    if (!hall?.scheme.floor?.dots.length) return { dots: [], objects: [] };
    const dots = hall.scheme.floor?.dots.map((item) => [item.x + this.gridSize / 2, item.y + this.gridSize / 2, v4()]) || [];
    const avgX = (dots.reduce((acc, i) => acc + i[0], 0)) / dots.length;
    const avgY = (dots.reduce((acc, i) => acc + i[1], 0)) / dots.length;
    const middleDot = [avgX, avgY];

    const objects = hall.scheme.objects?.map((item) => {
      const objectInstance = { ...OBJECT_TYPES[item.type] };
      if (!objectInstance) return;
      objectInstance.id = v4();
      objectInstance.fields = { ...objectInstance.defaultFields };

      objectInstance.fields.x = item.x + middleDot[0];
      objectInstance.fields.y = item.y + middleDot[1];
      objectInstance.fields.title = item.name;
      objectInstance.fields.width = item.width;
      objectInstance.fields.height = item.height;
      objectInstance.fields.rotation = item.rotation;
      objectInstance.fields.borderRadius = item.border_radius;
      objectInstance.fields.shapeCount = item.shape_count;
      objectInstance.fields.minCap = ['table', 'chair'].includes(item.type) ? item?.params.capacity_min : undefined;
      objectInstance.fields.maxCap = ['table', 'chair'].includes(item.type) ? item?.params.capacity_max : undefined;
      objectInstance.serverId = item.params?.id;
      return objectInstance;
    }).filter((i) => i) || [];

    return {
      dots,
      objects,
    };
  };
}

export default PlanServices;
