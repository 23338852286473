import React from 'react';

import './css/index.scss';
import PageTitle from '../../../../components/PageTitle';
import MyButton from '../../../../components/MyButton';

function PartnersForm(props) {
  return (
    <div className="partners_form">
      <div className="partners_form">
        <PageTitle text="ООО Гамбринус" />
        <MyButton text="Сохранить" className="partners_form_button" />
      </div>
    </div>
  );
}

export default PartnersForm;
