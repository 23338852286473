export const COLORS = {
  primary: '#3545DE',
  textPrimary: '#061F40',
  darkBlue: '#061F40',
  red: '#F44336',
  white: '#FFFFFF',
  background: '#F6F7F9',
  lightBlue: '#D7DAF8',
  textSecondary: '#7F8A99',

  border: '#E6E8EB',
  'primary-hover': '#C3C8F6',
};


export const REGEXP = {
  email: /\S+@\S+\.\S+/,
  imgSrc: /(?:\s+src="(.*?)"[^.]*?\/?>|&lt;\s+src="(.*?)"[^.]*?\/?&gt;)/gm,
  margin: /(?:\s+margin="(.*?)"[^.]*?\/?>|&lt;\s+margin="(.*?)"[^.]*?\/?&gt;)/gm,
};

