import React from 'react';
import './css/index.scss';

function PageTitle({ text }) {
  return (
    <div className={'page_title'}>
      <p className="page_title_text">{text}</p>
    </div>
  );
}

export default PageTitle;
