import { atom } from 'jotai';

const hallsAtom = atom([]);
const gettingHallsLoadingAtom = atom(false);
const savingHallLoadingAtom = atom(false);
const deletingHallLoadingAtom = atom(false);
const selectedHallAtom = atom(null);
const popUpedHallAtom = atom(null);

const updateSelectedHallAtom = atom(null, (get, set, update) => {
  const prevObj = get(selectedHallAtom);
  if (!prevObj) return;
  set(selectedHallAtom, { ...prevObj, ...update, isUpdated: true });
});

export {
  hallsAtom,
  gettingHallsLoadingAtom,
  savingHallLoadingAtom,
  deletingHallLoadingAtom,
  selectedHallAtom,
  popUpedHallAtom,
  updateSelectedHallAtom,
};
