import React from 'react';
import PageTitle from '../../components/PageTitle';
import MyButton from '../../components/MyButton';
import './css/index.scss';
import MyInput from '../../components/MyInput';

function Contacts(props) {
  return (
    <div className="contacts">
      <PageTitle
        text="Контакты"
        RightComponent={(
          <div>
            <MyButton
              text="Экспорт"
              className="contacts_button"
            />
          </div>

                       )}
      />
      <div className="contacts_filter">
        <MyInput placeholder="Поиск" />
        <MyButton
          secondary
          text="Импорт"
        />
      </div>
    </div>
  );
}

export default Contacts;
