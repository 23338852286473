import { atom } from 'jotai';
import { v4 } from 'uuid';
import PlanServices from '../services/PlanServices';

const schemeDotsAtom = atom([]);
const schemeDotsInitAtom = atom([]); // for checking if changes have been done

const gridDraggedPositionAtom = atom([0, 0]);
const gridZoomScaleAtom = atom(1, (get, set, val) => {
  if (val === 0) return set(gridZoomScaleAtom, 1);
  if (![1, -1].includes(val)) return;

  const currentVal = get(gridZoomScaleAtom);
  const newVal = +(currentVal + (val * PlanServices.gridZoomStep)).toFixed(1);

  if (val === 1 && newVal <= PlanServices.gridZoomMax) set(gridZoomScaleAtom, newVal);
  else if (val === -1 && newVal >= PlanServices.gridZoomMin) set(gridZoomScaleAtom, newVal);
});

const schemeMiddleDotAtom = atom([0, 0]);

const gridRefCurrentAtom = atom(null);

const objectsListAtom = atom([]);
const objectsListInitAtom = atom([]); // for checking if changes have been done

const selectedObjectAtom = atom(null);

const selectedObjectIndexAtom = atom(null, (get, set, val) => {
  const objectsList = get(objectsListAtom);
  const index = val < 0 ? objectsList.length + val : val;
  set(selectedObjectAtom, objectsList[index]);
  set(selectedObjectIndexAtom, index);
});

export {
  schemeDotsAtom,
  gridZoomScaleAtom,
  schemeMiddleDotAtom,
  gridDraggedPositionAtom,
  gridRefCurrentAtom,
  selectedObjectIndexAtom,
  objectsListAtom,
  selectedObjectAtom,

  schemeDotsInitAtom,
  objectsListInitAtom,
};
